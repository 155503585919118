import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { changePasswordFormName } from 'ducks/profile';
import InputField from 'components/forms/controls/V2/InputField';
import Button from 'components/forms/controls/ButtonV2';
import PasswordStrength from 'components/forms/PasswordStrength/PasswordStrength';
import warn from 'components/forms/PasswordStrength/warn';
import { required, strongPassword } from 'services/validate';

const validateOldPassword = required('Current Password');
const validateNewPassword = required('New Password');

const validate = (values) => {
  return values.password !== values.confirmPassword
    ? {
      confirmPassword: "Passwords don't match",
    }
    : {};
}

const renderNewPasswordField = (params) => {
  delete params.input.value;
  const { meta } = params;
  const { active, touched, warning } = meta;
  const showHint = (active || touched) && _.find(warning, (w) => w);
  return (
    <div>
      <InputField {...params} />
      {showHint && (
        <div className="password-hint-container">
          <PasswordStrength warning={warning || {}} />
        </div>
      )}
    </div>
  );
};

const ChangePasswordForm = ({ handleSubmit, submitting, title, invalid, onClickHandler }) => {
  const oldPasswordLabel = 'Current Password',
    newPasswordLabel = 'New Password',
    confirmLabel = 'Confirm Password';


  return (
    <form className="gm-form scp-reset-pass" onSubmit={handleSubmit}>
      <div className="back" onClick={() => onClickHandler(0)}>
        Back
      </div>
      <p className="form-title">{title}</p>
      <p className="title">{title}</p>
      <div className="form-field">
        <label className="field-title">{oldPasswordLabel}</label>
        <Field
          name="oldPassword"
          type="password"
          component={InputField}
          placeholder="********"
          validate={[validateOldPassword, strongPassword]}
        />
      </div>
      <div className="form-field">
        <label className="field-title">{newPasswordLabel}</label>
        <Field
          name="password"
          type="password"
          placeholder="********"
          component={renderNewPasswordField}
          validate={[validateNewPassword, strongPassword]}
        />
      </div>
      <div className="form-field">
        <label className="field-title">{confirmLabel}</label>
        <Field
          name="confirmPassword"
          type="password"
          component={InputField}
          placeholder="********"
        />
      </div>
      <Button type="submit" className="btn-save" disabled={invalid || submitting}>
        Change Password
      </Button>
    </form>
  );

}


ChangePasswordForm.propTypes = {
  children: PropTypes.element,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  title: PropTypes.string,
};

export default reduxForm({
  form: changePasswordFormName,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate: validate,
  warn,
})(ChangePasswordForm);
