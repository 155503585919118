import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect,useState } from 'react';
import Alert from 'components/widgets/Alert/Alert';
import classnames from 'classnames';

const UpdateNotification = ({ profile, user, onClose, fade = true }) => {

  const [updateProfileResult, setUpdateProfileResult] = useState(profile.operationResult);
  const [updatePaymentResult, setUpdatePaymentResult] = useState(null);
  const [paymentUpdating, setPaymentUpdating] = useState(undefined);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    return () => {
      onCloseAlert();
    };
  }, []);

  useEffect(() => {
    const userProfileUpdated =
      _.isNil(updateProfileResult) && !_.isNil(profile.operationResult);
    const paymentUpdated = !user.paymentUpdating && paymentUpdating;

    if (userProfileUpdated) {
      setUpdateProfileResult(profile.operationResult);
      setStatusMessage(profile.statusMessage);
    }

    if (paymentUpdated) {
      const { error } = user;
      setUpdatePaymentResult(_.isNil(error));
      setStatusMessage(
        error ? error.message : 'Payment method has been successfully updated'
      );
    }

    if (user.paymentUpdating !== paymentUpdating) {
      setPaymentUpdating(user.paymentUpdating);
    }
  }, [profile.operationResult, profile.statusMessage, user.paymentUpdating, user.error, paymentUpdating]);

  const onCloseAlert = () => {
    if (updateProfileResult !== null) {
      setUpdateProfileResult(null);
    }
    if (updatePaymentResult !== null) {
      setUpdatePaymentResult(null);
    }
    if (onClose) {
      onClose();
    }
  };


  if (_.isNil(updateProfileResult) && _.isNil(updatePaymentResult)) {
    return null;
  }

  const alertType = updateProfileResult || updatePaymentResult ? 'success' : 'danger';

  return (
    <div
      className={classnames(
        fade ? 'notification-container-animated' : 'notification-container-animated-no'
      )}>
      <Alert
        type={alertType}
        message={statusMessage}
        onClose={onCloseAlert}
        fade={fade}
      />
    </div>
  );
}

UpdateNotification.propTypes = {
  profile: PropTypes.object.isRequired,
  operationResult: PropTypes.oneOf([PropTypes.bool, PropTypes.null]),
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default UpdateNotification;
