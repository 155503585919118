import PropTypes from 'prop-types';
import React from 'react';
import { getCardImage } from '../../../../../services/utils';
import arrowImage from '../../../../../images/common/chevron-large.svg';
import './ProfileSelection.scss';

const ProfileSelection = ({onClickHandler, children, className}) =>{

  return (
    <div className={`profile-selection-container ${className}`}>
      <div className="profile-selection">
        <h3 className="title">Profile</h3>
        <div className="profile-selection-links">
          {(children || []).map((child, i) => (
            <div className="header">
              <div
                className="header-content"
                key={`profile-menu-${i}`}
                onClick={() => onClickHandler(i)}>
                <span className="text-icon">
                  <span className="icon">
                    <img src={getCardImage(child.icon)} alt="" className="arrow-image" />
                  </span>
                  <span className="text">{child.header}</span>
                </span>
                <span className="left-icon">
                  <img src={arrowImage} alt="" className="arrow-image" />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

}


ProfileSelection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.array,
  activeStepIndex: PropTypes.number,
  onClickHandler: PropTypes.func,
  className: PropTypes.string,
};

export default ProfileSelection;
