import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import InputField from 'components/forms/controls/V2/InputField';
import SelectField from 'components/forms/controls/V2/SelectField';
import Button from 'components/forms/controls/ButtonV2';
import { isEmpty, required } from 'services/validate';
import { HOME, getCountriesOption, getCountryStates } from 'services/utils';
import { hydrateHomeAddressFields } from '../../../../../services/api/transformers/addressTransformers';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { closeLoader } from '../../../../../ducks/ui';
import Alert from '../../../../../components/widgets/Alert/Alert';
import { clearFormError, setSubmitFailure } from '../../../../../ducks/registrationWizard';
import validPostalCode from 'postcode-validator';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const validateCountryRequired = required('Country');
const validateAddressLine1 = required('Address Line 1');
const validateCity = required('City');

const validate = (values) => {
  const errors = {};
  const zipCodeLabel = values.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';
  const StateLabel = values.addressCountry === 'CA' ? 'Province' : 'State';

  if (isEmpty(values.addressState)) {
    errors.addressState = `${StateLabel} is required`;
  }
  if (isEmpty(values.addressZipCode)) {
    errors.addressZipCode = `${zipCodeLabel} is required`;
  } else if (!validPostalCode.validate(values.addressZipCode, values.addressCountry)) {
    errors.addressZipCode = `${zipCodeLabel} is invalid`;
  }
  return errors;
};

const CreditCardForm = ({
  initialValues,
  getStripeIntent,
  allAddress,
  user,
  untouch,
  dispatch,
  onSubmit,
  handlePaymentMethod,
  isMobile,
  handleSubmit,
  submitting,
  title,
  invalid,
  onClickHandler,
  isCreateMode,
  formError,
  CountriesStateEnums: { countries, states },
}) => {
  const { stripeIntent, stripeIntentLoading } = user;

  const [updatingCard, setUpdatingCard] = useState(false);
  const [initialState, setInitialState] = useState({});
  const [validateCreditCard, setValidateCreditCard] = useState(false);
  const [stripe, setStripe] = useState();
  const [elements, setElements] = useState();

  useEffect(() => {
    if (initialValues) setInitialState(initialValues);
    getStripeIntent();
  }, []);

  const handleComponentState = (label, value) => {
    if (label == 'stripe') setStripe(value);
    else if (label == 'elements') setElements(value);
    else if (label == 'validateCreditCard') setValidateCreditCard(value);
  };

  const setAddress = () => {
    let newAddress = [...allAddress];
    let homeAddress = hydrateHomeAddressFields({
      ...initialState,
      fullName: _.get(user, ['me', 'fullName'], ''),
    });

    const oldHomeAddressIndex = newAddress.findIndex(
      (el) => el.addressType && el.addressType.includes(HOME)
    );
    if (oldHomeAddressIndex > -1) {
      const oldAddressObj = newAddress[oldHomeAddressIndex];
      let oldType = [..._.get(oldAddressObj, 'addressType', [])];
      if (oldType.length > 1) {
        let homeIndex = oldType.indexOf(HOME);
        if (homeIndex > -1) {
          oldType.splice(homeIndex, 1);
          newAddress[homeIndex] = { ...oldAddressObj, addressType: oldType };
          newAddress.push({ ...homeAddress, addressType: [HOME] });
        }
      } else {
        newAddress[oldHomeAddressIndex] = {
          ...homeAddress,
          addressType: [HOME],
        };
      }
    } else {
      newAddress.push({ ...homeAddress, addressType: [HOME] });
    }
    return newAddress;
  };

  const handleChange = (value, key) => {
    setInitialState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCountryChange = (value, key) => {
    if (initialState?.addressCountry !== value) {
      let obj = {
        [key]: value,
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipCode: '',
      };
      if (initialValues?.addressCountry == value) {
        obj['addressLine1'] = _.get(initialValues, ['addressLine1'], '');
        obj['addressLine2'] = _.get(initialValues, ['addressLine2'], '');
        obj['addressCity'] = _.get(initialValues, ['addressCity'], '');
        obj['addressState'] = _.get(initialValues, ['addressState'], '');
        obj['addressZipCode'] = _.get(initialValues, ['addressZipCode'], '');
      }
      setInitialState((prevState) => ({
        ...prevState,
        ...obj,
      }));

      untouch('addressLine1');
      untouch('addressLine2');
      untouch('addressCity');
      untouch('addressState');
      untouch('addressZipCode');
      initialize({
        ...initialState,
      });
    }
  };

  const onFormSubmit = async (data) => {
    dispatch(clearFormError());
    const addresses = setAddress();
    await onSubmit({ addresses: addresses, paymentInfo: data }).then((res) => {
      if (stripe) {
        stripe
          .confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              payment_method_data: {},
            },
          })
          .then(function (result) {
            if (result.error) {
              dispatch(setSubmitFailure(result.error.message));
              dispatch(closeLoader());
            } else {
              handlePaymentMethod({
                ...data,
                setupIntentId: _.get(user, ['stripeIntent', 'id']),
                addressId: _.get(res, 'addressId', ''),
              });
            }
          });
      }
    });
  };

  const clientSecret = _.get(stripeIntent, ['secret']);
  const options = {
    clientSecret: clientSecret,
    appearance: {
      variables: {
        fontFamily: "'RionaSans', Arial, Helvetica, sans-serif",
      },
      rules: {
        '.Label': {
          fontSize: isMobile ? '14px' : '12px',
          color: '#262837',
          textTransform: 'capitalize',
        },
        '.Input': {
          padding: isMobile ? '13px 12px 13px 16px' : '8px 8px',
          fontSize: isMobile ? '16px' : '13px',
          border: 'solid 1px #bac3ce',
        },

        '.Input:focus': {
          borderColor: '#70cbf1',
        },
        '.Input--invalid': {
          backgroundColor: 'rgba(254, 145, 137, 0.5)',
          boxShadow: 'none',
        },
        '.Error': {
          fontSize: '12px',
          color: '#ff0000',
        },
      },
    },
  };
  const countryIsNonUS = _.get(user, ['me', 'countryIsNonUS'], false);
  const allowedCountries = _.get(user, ['me', 'allowedCountries'], ['US']);
  const StateLabel = initialState.addressCountry === 'CA' ? 'Province' : 'State';
  const zipCodeLabel =
    initialState.addressCountry === 'CA' ? 'Postal Code' : 'Zip Code';

  return (
    <form className="gm-form" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="back visible" onClick={onClickHandler}>
        Back
      </div>
      <p className="title">{`${isCreateMode ? 'Add Card' : 'Update Card'}`}</p>
      <p className="form-title">{title}</p>
      <p className="cc-title headingv2">Credit or Debit Card</p>
      <Alert message={formError} />

      {!stripeIntentLoading && (
        <div className="line-fields">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              handleComponentState={handleComponentState}
              defaultValues={{
                billingDetails: {
                  name: _.get(user, ['me', 'fullName'], ''),
                  address: {
                    country: initialValues.addressCountry,
                    postal_code: initialValues.addressZipCode,
                  },
                },
              }}
            />
          </Elements>
        </div>
      )}
      <p className="form-title headingv2">Billing Address</p>
      <div className="billing-address">
        {countryIsNonUS && (
          <div className="form-row">
            <div className="form-col full-col">
              <div className="label-section required">Country</div>
              <Field
                name="addressCountry"
                component={SelectField}
                placeholder="Select"
                options={getCountriesOption(countries, allowedCountries)}
                validate={[validateCountryRequired]}
                onChange={(e, value) => handleCountryChange(value, 'addressCountry')}
              />
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="form-col full-col">
            <div className="label-section required">Address Line 1</div>
            <Field
              name="addressLine1"
              placeholder="123 Main Street"
              type="text"
              component={InputField}
              validate={[validateAddressLine1]}
              onChange={(e, value) => handleChange(value, 'addressLine1')}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-col full-col">
            <div className="label-section">Address Line 2</div>
            <Field
              name="addressLine2"
              placeholder="Apt 123"
              type="text"
              component={InputField}
              onChange={(e, value) => handleChange(value, 'addressLine2')}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-col city-col">
            <div className="label-section required">City</div>
            <Field
              name="addressCity"
              type="text"
              placeholder="Enter"
              component={InputField}
              validate={[validateCity]}
              onChange={(e, value) => handleChange(value, 'addressCity')}
            />
          </div>
          <div className="form-col state-col">
            <div className="label-section required">{StateLabel}</div>
            <Field
              name="addressState"
              component={SelectField}
              placeholder="Select"
              options={getCountryStates(
                states,
                initialState.addressCountry,
                'code',
                'code'
              )}
              onChange={(e, value) => handleChange(value, 'addressState')}
              typeahead={true}
              clearable={true}
            />
          </div>
          <div className="form-col zip-col">
            <div className="label-section required">{zipCodeLabel}</div>
            <Field
              name="addressZipCode"
              type="text"
              placeholder="Enter"
              component={InputField}
              label="Zipcode"
              onChange={(e, value) => handleChange(value, 'addressZipCode')}
            />
          </div>
        </div>
      </div>
      <Button
        type="submit"
        className="btn-save v2"
        disabled={invalid || submitting || !validateCreditCard}
      >
        {!isCreateMode ? 'Update Card' : 'Add New Card'}
      </Button>
    </form>
  );


}


CreditCardForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  title: PropTypes.string,
  isCreateMode: PropTypes.bool,
};

export default reduxForm({
  form: 'credit-card-form',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: false,
  validate,
})(CreditCardForm);
