import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/forms/controls/ButtonV2';
import threeDotsImage from '../../../../../images/common/three-dots.svg';
import './CreditCardList.scss';

const CreditCardList= ({onClickHandler, onBackHandler, isCreateMode, initialValues})=>{
  const { cardBrand, cardNumberLast4, cardholderName } = initialValues;

  return (
    <form className="gm-form">
      <div className="card-wrapper">
        <div className="back" onClick={() => onBackHandler(0)}>
          Back
        </div>
        <p className="title">Payment Information</p>
        {isCreateMode && (
          <div className="outer-button">
            <Button onClick={onClickHandler} className="card-add">
              Add New Card
            </Button>
          </div>
        )}
        {!isCreateMode && (
          <div className="card-items">
            <div className="card-item padded-card" onClick={onClickHandler}>
              <div className="card-info">
                <div>
                  <img className="brand-icon" data-brand={cardBrand} />
                </div>
                <div className="user-info">
                  <span className="name">{cardholderName}</span>
                  <span className="card-number">xxxx {cardNumberLast4}</span>
                </div>
              </div>
              <div>
                <img src={threeDotsImage} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );

}

CreditCardList.propTypes = {
  onClickHandler: PropTypes.func,
  isCreateMode: PropTypes.bool,
  initialValues: PropTypes.object,
  onBackHandler: PropTypes.func,
};

export default CreditCardList;
